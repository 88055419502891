<template>
  <CRow>
    <CCol md="12">
      <CCard class="clapans">
        <CCardHeader>
          <div class="row">
            <div class="col-md-6 d-flex justify-content-end">
            </div>
            <div class="col-md-6 d-flex justify-content-end align-items-center">
              <span class="font-lg alert-success mr-4">Общий долг: <b>{{ all_sum }}</b></span>
              <CButton color="success" @click="createNew()">
                Создать нового клиента
              </CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>

          <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableItems"
              :fields="tableFields"
              head-color="light"
              column-filter
              table-filter
              sorter
              :tableFilter="{'label':'Поиск','placeholder':'Введите слово для поиска'}"
          >
            <td slot="action" slot-scope="{ item,index }" class="d-flex">
              <CButton color="danger" @click="deleteItem(item,index)" class="mr-2">
                Удалить
              </CButton>
              <CButton color="success" @click="$router.push('/dashboard/'+item.id)" class="mr-2">
                Инфо
              </CButton>
            </td>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
const moment = require('moment');

export default {
  name: "MasterHubs",
  data() {
    return {
      all_sum: 0,
      warning: false,
      success: false,
      alert_message: '',
      warningModal: false,
      isSelect: false,
      action_switcher: '',
      action_switcher_city: '',
      id: "",
      file_path: '',
      fileData: '',
      tableItems: [],
      tableFields: [
        {key: "id", label: "ID"},
        {key: "created_at", label: "Дата создание"},
        {key: "place_count", label: "Кол-во мест"},
        {key: "race_number", label: "Номер рейса"},
        {key: "race_type", label: "Тип рейса"},
        {key: "weight", label: "Сумма веса"},
        {key: "sum", label: "Сумма"},
        {key: "action", label: "Действия"},
      ],
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    deleteItem(item, index) {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/downloads/delete/' + item.id).then(response => {
        app.tableItems.splice(index, 1);
      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },
    createNew() {
      this.$router.push('/clients/0');
    },
    viewItem(item, index) {
      let app = this;
      this.$router.push('/clients/' + item.code_of_client);
    },
    getResults() {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/downloads/list').then(response => {
        let downloads = response.data.downloads;
        for (const [key, value] of Object.entries(downloads)) {
          value.action = true;
          value.created_at = moment(value.created_at).format('DD.MM.Y')
          app.tableItems.push(value);
        }
      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },

  },
};
</script>
<style>
.clapans .form-inline {
  margin-left: 15px;
}

.alert_block {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>
